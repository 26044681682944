import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import { injectState, provideState } from 'reaclette'
import { injectStripe } from 'react-stripe-elements'
import { Order } from '@vates/www-xo-utils'

import { getApi } from '../../api'
import {
  addOrReplaceStripeCard,
  STRIPE,
  TRANSFER,
  stripeSecureAuthentication
} from '../purchase-utils'
import { makeStepperData } from '../../utils'
import ConfirmModal from '../components/confirm-modal'
import CreditCardRecap from '../new-purchase/payment/credit-card-recap'
import ManualOrderPayment from './manual-order-payment'
import ManualOrderSummary from './manual-order-summary'
import Recap from '../new-purchase/recap'
import Stepper from '../new-purchase/stepper'

export const PLAN_DETAIL = 1
export const BILLING_INFO = 2
export const PAYMENT_METHOD = 3
export const CREDIT_CARD_RECAP = 3.1
export const RECAP = 4

const STEPS_PROPS_TEMPLATE = {
  [PLAN_DETAIL]: {
    state: {
      account: '',
      country: '',
      currency: '',
      initialOrder: '',
      order: '',
      preSelectedProduct: '',
      role: '',
      selfbound: '',
      xwToken: ''
    },
    effects: {
      addToOrder: '',
      handleSelfbound: '',
      resetOrder: '',
      updateOrder: ''
    }
  },
  [BILLING_INFO]: {
    state: {
      account: '',
      baseUrl: '',
      country: '',
      role: '',
      xwToken: ''
    },
    effects: {
      updateBillingInfo: ''
    }
  },
  [PAYMENT_METHOD]: {
    state: {
      account: '',
      order: '',
      payment: ''
    },
    effects: {
      updatePayment: ''
    }
  },
  [CREDIT_CARD_RECAP]: {
    state: {},
    effects: {}
  },
  [RECAP]: {
    state: {
      account: '',
      order: '',
      payment: ''
    },
    effects: {}
  }
}

const STEPS = [
  {
    stepIndex: PLAN_DETAIL,
    stepName: 'Plan details',
    disablePreviousButton: true
  },
  {
    stepIndex: BILLING_INFO,
    stepName: 'Billing information',
    disablePreviousButton: true
  },
  {
    stepComponent: ManualOrderPayment,
    stepIndex: PAYMENT_METHOD,
    stepName: 'Payment method',
    nextText: () => 'Confirm',
    onNextStep: async (effects, state) => {
      try {
        /**
         * Transfer payment
         */
        if (state.payment.paymentMethod === TRANSFER) {
          if (state._order.status !== Order.ORDER_STATUS.TRANSFER_EXPECTED) {
            await getApi(state.role).chooseTransferPayment(state._order.id)
          }
          effects.goToStep(RECAP)
        }

        /**
         * Credit card payment
         */
        if (state.payment.paymentMethod === STRIPE) {
          if (state._order.status !== Order.ORDER_STATUS.CARD_EXPECTED) {
            await getApi(state.role).chooseCardPayment(state._order.id)
          }

          /**
           * If user want to use new card, we add it or replace the existing card
           */
          if (state.payment.usePreviousCard !== true) {
            await effects.createStripeToken(state.account.billingInfo.lastName)
            await addOrReplaceStripeCard(
              state.xwToken,
              state.account,
              state.payment.cardToken
            )
            await effects.refreshAccountInfo()
          }

          effects.goToStep(CREDIT_CARD_RECAP)
        }
        effects.handleChangeOrderSaved(true)
      } catch (error) {
        effects.handleError(error)
      }
    },
    disablePreviousButton: true
  },
  {
    stepHidden: true,
    stepComponent: CreditCardRecap,
    stepIndex: CREDIT_CARD_RECAP,
    stepName: 'Payment method',
    nextText: () => 'Pay',
    onNextStep: async (effects, state, props) => {
      try {
        let order
        if (state._order.paymentModel.type === 'paidPeriod') {
          order = await getApi(state.role).intentOneShotPaymentManualOrder(
            state.xwToken,
            state._order.id
          )

          // If 3D secure
          if (order.clientSecret) {
            await stripeSecureAuthentication(
              props.stripe.handleCardPayment,
              order.clientSecret
            )
            order = await getApi(state.role).confirmOneShotPaymentManualOrder(
              state.xwToken,
              state._order.id
            )
          }
        } else {
          effects.goToStep(PAYMENT_METHOD)
        }

        effects.goToStep(RECAP)
      } catch (error) {
        effects.handleError(error)
      }
    },
    onPreviousStep: effects => {
      effects.goToStep(PAYMENT_METHOD)
    }
  },
  {
    stepComponent: Recap,
    stepIndex: RECAP,
    stepName: 'Recap',
    nextText: () => 'Go to account',
    onNextStep: async (effects, state) => {
      try {
        effects.redirectToAccount()
      } catch (error) {
        effects.handleError(error)
      }
    },
    disablePreviousButton: true
  }
]

const getCorrectstep = (account, initialOrder) => {
  if (
    initialOrder.status === Order.ORDER_STATUS.CARD_EXPECTED ||
    initialOrder.status === Order.ORDER_STATUS.CARD_RECURRING_PAYMENT_PENDING ||
    initialOrder.status === Order.ORDER_STATUS.CARD_ONESHOT_PAYMENT_PENDING
  ) {
    if (account.stripe && account.stripe.card) {
      return CREDIT_CARD_RECAP
    }
  }
  return PAYMENT_METHOD
}

const getStep = currentStep =>
  STEPS.find(step => step.stepIndex === currentStep)

const withState = provideState({
  initialState: () => ({
    currentStep: 3,
    waiting: false,
    modalAction: undefined,
    modalActionArgs: [],
    modalBody: undefined,
    modalStatus: false
  }),
  effects: {
    async initialize(effects) {
      effects.initCurrentStep()
    },
    initCurrentStep(_) {
      if (this.state.initialOrder) {
        this.state.currentStep = getCorrectstep(
          this.state.account,
          this.state.initialOrder
        )
      } else {
        window.location.replace('/#/new-purchase')
      }
    },
    async previous(effects) {
      this.state.waiting = true
      getStep(this.state.currentStep).onPreviousStep(effects, this.state)
      this.state.waiting = false
    },
    async next(effects) {
      this.state.waiting = true
      await getStep(this.state.currentStep).onNextStep(
        effects,
        this.state,
        this.props
      )
      this.state.waiting = false
    },
    goToStep(effects, stepNumber) {
      this.state.currentStep = stepNumber
    },
    async createStripeToken(effects, lastname) {
      try {
        const { token } = await this.props.stripe.createToken({
          name: lastname
        })
        if (token) {
          effects.updatePayment({
            cardToken: token.id,
            cardLegalInfo: token.card
          })
        }
      } catch (error) {
        effects.handleError(error)
      }
    },
    confirm(effects, modalAction, modalActionArgs, modalBody) {
      this.state.modalAction = modalAction
      this.state.modalActionArgs = modalActionArgs
      this.state.modalBody = modalBody
      this.state.modalStatus = true
    },
    closeModal() {
      this.state.modalStatus = false
      this.state.modalAction = undefined
      this.state.modalActionArgs = []
      this.state.modalBody = undefined
    }
  }
})

const ManualOrder = ({ effects, state }) => (
  <Container fluid>
    <Col md={{ size: 10, offset: 1 }}>
      <Row>
        <Col md="9">
          <Stepper
            currentStep={state.currentStep}
            next={effects.next}
            previous={effects.previous}
            props={makeStepperData(state, effects, STEPS_PROPS_TEMPLATE)}
            steps={STEPS}
            waiting={state.waiting}
          />
        </Col>
        <Col md="3">
          <div style={{ marginTop: '8em' }}>
            <ManualOrderSummary billingInfo={state.account.billingInfo} />
          </div>
        </Col>
      </Row>
    </Col>
    <ConfirmModal
      close={effects.closeModal}
      modalAction={state.modalAction}
      modalActionArgs={state.modalActionArgs}
      modalBody={state.modalBody}
      modalStatus={state.modalStatus}
    />
  </Container>
)

export default injectStripe(withState(injectState(ManualOrder)))
