import { trim } from 'lodash'
import React from 'react'
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Form,
  FormGroup,
  Input
} from 'reactstrap'
import { injectState, provideState } from 'reaclette'

import Loader from '../components/loader'
import VatesLogo from '../../imgs/vates-logo.png'

const withState = provideState({
  initialState: () => ({
    email: '',
    password: ''
  }),
  effects: {
    handleChange: (_, { target: { name, value } }) => ({
      [name]: value
    })
  },
  computed: {
    disabled: state => trim(state.email) === '' || trim(state.password) === ''
  }
})

const AuthentificationForm = ({ effects, state, onSubmit, waiting }) => (
  <div className="sm-center">
    {waiting ? (
      <Loader />
    ) : (
      <Card body>
        <CardTitle>
          <div className="text-center">
            <img src={VatesLogo} alt="Vates" width="100" />
            <div
              style={{
                fontSize: '30px'
              }}
              className="text-muted"
            >
              Welcome back!
            </div>
          </div>
        </CardTitle>
        <CardBody>
          <br />
          <Form
            onSubmit={e => {
              e.preventDefault()
              onSubmit(state.email, state.password)
            }}
          >
            <FormGroup row>
              <Input
                type="text"
                name="email"
                placeholder="Email"
                value={state.email}
                onChange={effects.handleChange}
                required
              />
            </FormGroup>
            <FormGroup row>
              <Input
                type="password"
                name="password"
                placeholder="Password"
                value={state.password}
                onChange={effects.handleChange}
                required
              />
            </FormGroup>
            <FormGroup row>
              <Button
                color="success"
                disabled={state.disabled}
                type="submit"
                block
              >
                Sign in
              </Button>
            </FormGroup>
          </Form>
        </CardBody>
      </Card>
    )}
  </div>
)

export default withState(injectState(AuthentificationForm))
