import '../../../style/radio-buttons.css'
import Currency from 'react-currency-formatter'
import React, { Fragment } from 'react'
import {
  Button,
  ButtonGroup,
  Col,
  Collapse,
  Row,
  UncontrolledTooltip
} from 'reactstrap'
import { isEmpty } from 'lodash'
import { injectState, provideState } from 'reaclette'

import {
  HAS_DISCOUNT_YEARLY,
  PAID_PERIOD,
  SUBSCRIPTION,
  getPlanFormula,
  getProductsConfig
} from '../../purchase-utils'
import { generateId } from '../../../utils'

const getUniqProduct = order =>
  order &&
  order.cart &&
  order.cart.find(cartItem => cartItem.type === 'product')

const withState = provideState({
  initialState: () => ({
    planFormula: {
      [SUBSCRIPTION]: [],
      [PAID_PERIOD]: []
    },
    activeFormula: undefined
  }),
  effects: {
    async initialize(effects) {
      await effects.getPlanFormula()
      await effects.initializePaymentModel()
    },
    async initializePaymentModel(effects) {
      if (!this.state._order.paymentModel.type) {
        if (this.state.initialOrder) {
          effects.chooseFormula(this.state.initialOrder.paymentModel.type)
          effects.choosePeriod(this.state.initialOrder.paymentModel.year)
        } else {
	  const uniqProduct = getUniqProduct(this.state._order)
          const productConfig = getProductsConfig()[uniqProduct.productId]
          //const productConfig =
            //getProductsConfig()[this.state.uniqueProduct.productId]
	//console.log(this.state._order && this.state._order.cart[0])
	//console.log(this.state && this.state.uniqProduct && this.state.uniqProduct.productId)
          effects.chooseFormula(productConfig.default)
        }
      }
    },
    async getPlanFormula(effects) {
      try {
        const planFormula = await getPlanFormula(
          this.props.product.id,
          this.props.role,
          this.props.account
        )
        this.state.planFormula = planFormula

        // If the customer has already chosen his plan
        if (!isEmpty(this.props.product.planFormula)) {
          this.state.activeFormula = this.props.product.planFormula.type
        } else {
          // If it's an upgrade, we don't provide to change the payment method (we keep the same payment method as the initial purchase)
          this.state.activeFormula = planFormula.defaultChoice
        }
      } catch (error) {
        effects.handleError(error)
      }
    },
    toogleActiveFormula(effects, typeOfFormula) {
      this.state.activeFormula = typeOfFormula
      // Reset planFormula and quantity if user change the type of formula
      this.props.updateOrder(this.props.product.id, {
        planFormula: {},
        quantity: 1
      })
      effects.chooseFormula(typeOfFormula)
    }
  },
  computed: {
    disableSubscription: ({ planFormula }) =>
      isEmpty(planFormula[SUBSCRIPTION]),
    disablePaidPeriod: ({ planFormula }) => isEmpty(planFormula[PAID_PERIOD]),
    showQuantityInput: ({ activeFormula }, { uniqueProduct }) =>
      (activeFormula === SUBSCRIPTION &&
        getProductsConfig()[uniqueProduct.productId][SUBSCRIPTION].multiple) ||
      (activeFormula === PAID_PERIOD &&
        getProductsConfig()[uniqueProduct.productId][PAID_PERIOD].multiple)
  }
})

const Subscription = ({ effects, state, product, updateOrder, currency }) => (
  <Fragment>
    <Row>
      <Col>
        <p className="text-muted">Choose your plan</p>
      </Col>
    </Row>
    <Row>
      <Col>
        <ButtonGroup>
          <Button
            active={state._order.paymentModel.type === SUBSCRIPTION}
            color="dark"
            id="commitment-tooltip-id"
            onClick={() => effects.toogleActiveFormula(SUBSCRIPTION)}
            outline
            style={{ width: '200px' }}
            disabled={state.disableSubscription}
          >
            Subscription
          </Button>
          <Button
            active={state._order.paymentModel.type === PAID_PERIOD}
            color="dark"
            onClick={() => effects.toogleActiveFormula(PAID_PERIOD)}
            outline
            style={{ width: '200px' }}
            disabled={state.disablePaidPeriod}
          >
            Paid period
          </Button>
        </ButtonGroup>
        <UncontrolledTooltip placement="top" target="commitment-tooltip-id">
          There is no commitment in Xen Orchestra, you can cancel the renewal at
          any time
        </UncontrolledTooltip>
      </Col>
    </Row>
    <br />
    <Collapse isOpen={state.activeFormula === SUBSCRIPTION}>
      <ul className="list" style={{ listStyle: 'none' }}>
        {state.planFormula &&
          state.planFormula.subscription.map((subscription, index) => (
            <li className="list__item" key={index}>
              <input
                required={state.activeFormula === SUBSCRIPTION}
                type="radio"
                className="radio-btn"
                name={`${product.id}-sub-choice`}
                id={`${product.id}-${index}-sub-btn`}
                onChange={() => {
                  updateOrder(product.id, {
                    planFormula: { type: SUBSCRIPTION, ...subscription }
                  })
                  effects.choosePeriod(subscription.year)
                }}
                checked={
                  (product.planFormula.type === SUBSCRIPTION &&
                    product.planFormula.year === subscription.year) ||
                  false
                }
              />
              <label
                htmlFor={`${product.id}-${index}-sub-btn`}
                className="label"
              >
                <span className="badge badge-dark" style={{ fontSize: '16px' }}>
                  <Currency
                    quantity={subscription.tax.total}
                    currency={currency}
                  />
                </span>
                {subscription.year > 0 && HAS_DISCOUNT_YEARLY(product.id) && (
                  <span
                    className="badge badge-warning"
                    style={{ fontSize: '14px' }}
                  >
                    SAVE 10%
                  </span>
                )}
                &nbsp;
                <span className="text-muted">
                  per {subscription.year === 0 ? 'month' : 'year'}
                </span>
              </label>
            </li>
          ))}
      </ul>
    </Collapse>
    <Collapse
      isOpen={state.activeFormula === PAID_PERIOD}
      style={{ marginLeft: '200px' }}
    >
      <ul className="list" style={{ listStyle: 'none' }} required>
        {state.planFormula &&
          state.planFormula[PAID_PERIOD].map((prepaid, index) => (
            <li className="list__item" key={generateId()}>
              <input
                required={state.activeFormula === PAID_PERIOD}
                type="radio"
                className="radio-btn"
                name={`${product.id}-prepaid-choice`}
                id={`${product.id}-${index}-prepaid-btn`}
                checked={
                  (product.planFormula.type === PAID_PERIOD &&
                    product.planFormula.year === prepaid.year) ||
                  false
                }
                onChange={() => {
                  updateOrder(product.id, {
                    planFormula: { type: PAID_PERIOD, ...prepaid }
                  })
                  effects.choosePeriod(prepaid.year)
                }}
              />
              <label
                htmlFor={`${product.id}-${index}-prepaid-btn`}
                className="label"
              >
                {prepaid.year} year{prepaid.year > 1 && 's'} &nbsp; &nbsp;
                <span className="badge badge-dark" style={{ fontSize: '16px' }}>
                  <Currency quantity={prepaid.tax.total} currency={currency} />
                </span>
                {!isEmpty(prepaid.discount) && (
                  <span
                    className="badge badge-warning"
                    style={{ fontSize: '14px' }}
                  >
                    SAVE{' '}
                    <Currency
                      quantity={prepaid.discount.amount * -1}
                      currency={currency}
                    />
                  </span>
                )}
              </label>
            </li>
          ))}
      </ul>
    </Collapse>
  </Fragment>
)

export default withState(injectState(Subscription))
