import React from 'react'
import { injectState, provideState } from 'reaclette'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

const withState = provideState({
  initialState: () => ({
    resellerModal: false
  }),
  effects: {
    initialize(effects) {
      if (this.state.role === 'reseller') {
        effects.openModal()
      }
    },
    closeModal() {
      this.state.resellerModal = false
    },
    openModal() {
      this.state.resellerModal = true
    },
    redirectToPartnerPortal() {
      window.location.replace(this.state.config.partnerPortalFrontEndUrl)
    }
  }
})

const ResellerModal = ({ effects, state }) => (
  <Modal isOpen={state.resellerModal}>
    <ModalHeader toggle={effects.closeModal}>Partner</ModalHeader>
    <ModalBody>
      You are currently marked as a Partner. Please place your orders via our
      Partner Portal
      <br />
      Orders placed through this current order form will not offer your Partner
      Discount nor bring you any points.
    </ModalBody>
    <ModalFooter>
      <Button color="primary" onClick={effects.redirectToPartnerPortal}>
        Go to the Partner Portal
      </Button>
    </ModalFooter>
  </Modal>
)

export default withState(injectState(ResellerModal))
